html {
  /*background: #e3e3e3;*/
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 500;
}

.Card-md {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
}


h1 {
  font-family: 'Open Sans', sans-serif;
  font-size:80px;
  line-height:90px;
  font-weight:700;
}
h2 {
  font-family: 'Open Sans', sans-serif;
  font-size:36px;
  line-height:38px;
  font-weight:300;
  text-transform:uppercase;
}
h3 {
  font-family: 'Open Sans', sans-serif;
  font-size:24px;
  line-height:30px;
  padding-bottom:6px;
  font-weight:400;
}
h4 {
  font-family: 'Open Sans', sans-serif;
  font-size:19px;
  line-height:24px;
  font-weight:600;
}
h5 {
  font-family: 'Open Sans', sans-serif;
  font-size:16px;
  line-height:24px;
  font-weight:400;
}

ul {
  list-style: none;
}

.container {
  width: 98%;
  max-width: 1170px;
  margin: 0 auto;
  /* fix problem scroll spy with work & education */
  padding-bottom: 0 !important;
}

.active {
  color: #2196f3 !important;
}

.heading {
  text-align: center;
  padding-bottom: 14px;
  padding-top: 14px;
}

.heading p {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  padding-bottom: 22px;
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .heading {
    padding-bottom: 25px;
  }
}